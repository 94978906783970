import logo from '../../images/logo.svg';
import VISA from '../../images/visa.svg';
import Mastercard from '../../images/mastercard.svg';
import ApplePay from '../../images/apple-pay.svg';
import GooglePay from '../../images/google-pay.svg';
import Banks from '../../images/banks.svg';
import Arrow from '../../images/arrow.svg';
import './Header.scss';
import { MButton } from '../Button/Button';
import { motion } from 'framer-motion';
import { topAnimation, rightAnimation, bottomAnimation, leftAnimation} from '../../animation/animation';

function Header() {
  return (
      
      <motion.header className="header"
      initial='hidden'
      whileInView='visible'
      viewport={{amount: 0.2, once: true}}
      >
        <div className='overlay'>
          <div className='header__content'>
        <div className='header__container'>
        <motion.img variants={topAnimation} src={logo} className="header__logo" alt="logo" />
        <motion.div custom={2} variants={rightAnimation} className='header__container-link'>
          <a className='header__link' href='mailto:info@payment-guys.com' target='_blank' rel='noreferrer' >Start Using PaymentGuys</a>
          <img src={Arrow} className='header__icon' alt='Arrow icon' />
        </motion.div>
        </div>

        <motion.h1 custom={1} variants={leftAnimation} className='header__title'>Accelerate Your Business&#160;with the <span className='header__title-span'>New&#160;Era of&#160;Payment </span>Acceptance</motion.h1>
        <motion.div custom={2} variants={leftAnimation} className='header__image-container'>
          <img src={VISA} className="header__icon" alt="VISA payment system icon" />
          <img src={Mastercard} className="header__icon" alt="Mastercard payment system icon" />
          <img src={ApplePay} className="header__icon" alt="Apple Pay payment system icon" />
          <img src={GooglePay} className="header__icon" alt="Google Pay payment system icon" />
          <img src={Banks} className="header__icon" alt="Other banks icon" />
        </motion.div>

        <MButton custom={2} variants={bottomAnimation} text={'Get Started Now'} />
        
        </div>
        </div>
      </motion.header>
  );
}

export default Header;
