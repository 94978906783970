import "./Card.scss";
import { forwardRef } from 'react';
import { motion } from 'framer-motion';

const Card = forwardRef(({ type, image, subtitle, title, text, imageXXL }, ref) => {
  return (
    <article ref={ref} className={`card ${type ? 'card-end' : 'card-start'}`}>
      <picture>
        <source srcSet={image} media="(max-resolution: 192dpi)" />
        <source srcSet={imageXXL} media="(max-resolution: 193dpi)" />
        <img className="card__image" src={image} alt="card" />
      </picture>

      <div className={type ? 'card__box-end' : ''}>
      <p className="card__description">{subtitle}</p>
      <h4 className="card__title">{title}</h4>
      <p className="card__text">{text}</p>
      </div>
    </article>
  );
})

export default Card;

export const MCard = motion(Card);