import ImageOne from "../images/potential.jpg";
import ImageTwo from "../images/potential2.jpg";
import ImageThree from "../images/potential3.jpg";
import ImageFour from "../images/potential4.jpg";
import ImageFive from "../images/potential5.jpg";

import ImageOneXXL from "../images/potential1-xxl.jpg";
import ImageTwoXXL from "../images/potential2-xxl.jpg";
import ImageThreeXXL from "../images/potential3-xxl.jpg";
import ImageFourXXL from "../images/potential4-xxl.jpg";
import ImageFiveXXL from "../images/potential5-xxl.jpg";

export const arr = [
  {
    image: ImageOne,
    imageXXL: ImageOneXXL,
    subtitle: "Payment Acceptance",
    title: "Seamlessly accept payments with ease",
    text: "Securely process payments from your customers with PaymentGuys Platform's intuitive payment acceptance solution. Enjoy a smooth and hassle-free payment experience, ensuring customer satisfaction and business growth.",
  },
  {
    image: ImageTwo,
    imageXXL: ImageTwoXXL,
    subtitle: "Link Payments",
    title: "Share payment links effortlessly",
    text: "Simplify payment collection by generating customized payment links with a few clicks. Share these links via email, messaging apps, or social media, enabling convenient and quick transactions.",
  },
  {
    image: ImageThree,
    imageXXL: ImageThreeXXL,
    subtitle: "QR Payments",
    title: "Fast and contactless payment solution",
    text: "Enable contactless payments by generating QR codes for customers to scan and complete transactions swiftly. Provide a seamless payment experience that is efficient, secure, and aligned with the latest payment trends.",
  },
  {
    image: ImageFour,
    imageXXL: ImageFourXXL,
    subtitle: "Mail Payments",
    title: "Convenient payment collection via email",
    text: "Easily request payments from customers by sending personalized payment requests via email. Simplify the payment process for both parties with a direct and user-friendly approach.",
  },
  {
    image: ImageFive,
    imageXXL: ImageFiveXXL,
    subtitle: "Verify Identity",
    title: "Ensure secure and reliable transactions",
    text: "Enhance payment security by verifying the identity of customers through PAYMENTGUYS		Platform's robust verification system. Mitigate fraud risks and build trust with your customers, establishing a safe payment environment.",
  },
];
