import './Button.scss';
import { forwardRef } from 'react';
import { motion } from 'framer-motion';

const Button = forwardRef(({text}, ref) => {
  return (
    <button ref={ref} className='button'><a className='button__link' href='mailto:info@payment-guys.com' target='_blank' rel='noreferrer' >{text}</a></button>
  );
})

export default Button;

export const MButton = motion(Button);