import React from 'react';
import './Footer.scss';
import IconOne from '../../images/footer1.svg';
import IconTwo from '../../images/footer2.svg';
import IconThree from '../../images/footer3.png';
import IconFour from '../../images/footer4.svg';
import IconFive from '../../images/footer5.svg';
import IconSix from '../../images/footer6.svg';
import { motion } from 'framer-motion';
import {
  bottomAnimation,
  leftAnimation,
  spawnAnimation,
} from '../../animation/animation';

function Footer() {
  var currentYear = new Date().getFullYear();

  return (
    <motion.footer
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className="footer">
      <div className="footer__container">
        <motion.p
          custom={1}
          variants={leftAnimation}
          className="footer__title">{`© ${currentYear} PaymentGuys. All Right Reserved`}</motion.p>

        <div className="footer__icons">
          <motion.img
            custom={2}
            variants={spawnAnimation}
            src={IconOne}
            className="footer__icon"
            alt="icon"
          />
          <motion.img
            custom={3}
            variants={spawnAnimation}
            src={IconThree}
            className="footer__icon"
            alt="icon"
          />
          <motion.img
            custom={4}
            variants={spawnAnimation}
            src={IconFour}
            className="footer__icon"
            alt="icon"
          />
          <motion.img
            custom={5}
            variants={spawnAnimation}
            src={IconFive}
            className="footer__icon"
            alt="icon"
          />
          <motion.img
            custom={6}
            variants={spawnAnimation}
            src={IconTwo}
            className="footer__icon"
            alt="icon"
          />
          <motion.img
            custom={7}
            variants={spawnAnimation}
            src={IconSix}
            className="footer__icon"
            alt="icon"
          />
        </div>
      </div>

      <motion.p custom={6} variants={bottomAnimation} className="footer__text">
        PAYMENTGUYS FINANCE LTD. (Incorporation Number: BC1417083) (Business
        Number: 751449018BC0001) with the registered address: 408 - 55 WATER
        STREET OFFICE# 8936 VANCOUVER BC V6B 1A1 CANADA is an authorized Payment
        Institution and regulated by MSB registration number: M23066305
      </motion.p>
    </motion.footer>
  );
}

export default Footer;
