import Banks from "../../images/financial.jpg";
import "./Global.scss";
import { motion } from 'framer-motion';
import { rightAnimation, leftAnimation } from '../../animation/animation';

function Global() {
  return (
    <motion.section
    initial='hidden'
    whileInView='visible'
    viewport={{amount: 0.2, once: true}}
     className="global">
      
      <span className="global__ellipse"></span>
      <div className="global__block-info">
        <motion.h2 custom={1} variants={leftAnimation} className="global__title">Leading the Global Financial Sector</motion.h2>

        <motion.h3 custom={2} variants={leftAnimation} className="global__subtitle">Trusted by 500+ Banks</motion.h3>

        <motion.p custom={3} variants={leftAnimation} className="global__paragraph">
          With a commitment to constant innovation and expansion, we are
          dedicated to broadening our network and connectivity. Every month, we
          integrate 10 to 15 new banks from around the world, ensuring that our
          clients have access to an extensive range of financial institutions,
          regardless of their geographical location. Our track record of
          successful partnerships with banks worldwide speaks volumes about the
          trust placed in our expertise and solutions. By choosing PAYMENTGUYS, you can
          be confident that you are partnering with a reliable industry leader
          at the forefront of the global financial sector.
        </motion.p>
      </div>

      <motion.img variants={rightAnimation} src={Banks} className="global__image" alt="Banks icon" />

    </motion.section>
  );
}

export default Global;
