import React from "react";
import { Link } from "react-router-dom";
import { MButton } from "../Button/Button";
import Logo from "../../images/logo.svg";
import "./Breeze.scss";
import { motion } from 'framer-motion';
import { rightAnimation, bottomAnimation, leftAnimation } from '../../animation/animation';

function Breeze() {
  return (
    <motion.section
    initial='hidden'
    whileInView='visible'
    viewport={{amount: 0.2, once: true}}
    className="breeze">
      <span className="breeze__ellipse"></span>

      <motion.h2 custom={1} variants={bottomAnimation} className="breeze__title">Embrace the Payment Breeze</motion.h2>

      <motion.h3 custom={2} variants={bottomAnimation} className="breeze__subtitle">
        Streamline your payment acceptance process and experience the ease of
        PaymentGuys innovative solutions.
      </motion.h3>

      <motion.p custom={3} variants={bottomAnimation} className="breeze__paragraph">
        Unlock the payment breeze and enjoy seamless transactions, enhanced
        customer satisfaction, and accelerated business growth.
      </motion.p>

      <MButton custom={4} variants={bottomAnimation} text={"Get Started Now"} />

      <motion.div
          initial='hidden'
          whileInView='visible'
          viewport={{amount: 0.2, once: true}}
          className="breeze__block-info">

        <motion.img custom={5} variants={leftAnimation} src={Logo} className="breeze__logo" alt="logo" />
        <div className="breeze__list">
        <Link to='/privacy' className="breeze__link">
          <motion.p custom={6} variants={rightAnimation} className="breeze__text-link" >Privacy Policy</motion.p>
        </Link>
        <Link to='/terms' className="breeze__link">
        <motion.p custom={7} variants={rightAnimation} className="breeze__text-link" >Terms of Use</motion.p>
        </Link>
        </div>


      </motion.div>
    </motion.section>
  );
}

export default Breeze;
