import "./Potential.scss";
import { arr } from "../../constants/data";
import { MCard } from "../Card/Card";
import { motion } from 'framer-motion';
import { bottomAnimation, spawnAnimation} from '../../animation/animation';

function Potential() {
  return (
    <motion.section
    initial='hidden'
    whileInView='visible'
    viewport={{amount: 0.2, once: true}}
    className="potential">

      <motion.h2 custom={1} variants={bottomAnimation} className="potential__title">Unlock Your Potential</motion.h2>
      <motion.h3 custom={2} variants={bottomAnimation} className="potential__subtitle">
        Join 25,000+ Small, Medium, and&#160;Enterprise&#160;Businesses
      </motion.h3>

      <motion.div
          initial='hidden'
          whileInView='visible'
          viewport={{amount: 0.2, once: true}}
      className="potential__cards">
        {arr.map((el, i) => (
          <MCard
          custom={i + 1} variants={spawnAnimation}
            type={i <= 2 ? false : true}
            key={i}
            image={el.image}
            subtitle={el.subtitle}
            title={el.title}
            text={el.text}
            imageXXL={el.imageXXL}
          />
        ))}
      </motion.div>
    </motion.section>
  );
}

export default Potential;
