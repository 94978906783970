function Privacy() {
  return (
    <div className="info">
      <h1 className="info__title">Privacy Policy</h1>
      <div>
        At PAYMENTGUYS FINANCE LTD. ("We", "Us", "Our", "PAYMENTGUYS"), We
        respect Your privacy and data security. We aim to provide clear
        information on how We will Use Your data and how You can control that.
        Therefore, We strongly suggest that You carefully read this Privacy
        Policy before using services provided by us (collectively, the
        "Service").
        <br />
        <br />
        In case You have any further questions or concerns please feel free to
        reach out to&nbsp;
        <a target="_blank" rel="noreferrer" href="mailto:info@payment-guys.com">
          info@payment-guys.com
        </a>
        , We will be happy to address them. <br />
        <br /> This Privacy Policy, along with the Terms of Service shall
        constitute the entire Agreement (further - "Agreement") between You and
        PAYMENTGUYS. By using the Service, You acknowledge that You accept and
        agree to be bound by the terms in the Agreement.
        <br />
        <br />
        <strong>1. Ours and Your responsibilities </strong>
        <br />
        <br /> By Using Our Service You agree to the Terms of Service and this
        Privacy Policy. <br />
        <br /> Your responsibility under the Agreement between You and Us (Terms
        of Service) is to carefully examine this Privacy Policy and provide the
        correct data about Yourself. <br />
        <br /> Our responsibility is to provide You with the most clear and
        up-to-date information on how We process Your data, ensure the Service
        safety and security, and keep Your data safe.&nbsp;
        <br />
        <br />
        <strong>2. When do We collect Your data </strong>
        <br />
        <br /> As soon as You sign up for Our Service We start collecting Your
        personal data. We collect it solely for the purpose of being able to
        provide You with Our Service and won't process it in any other way. For
        the same reason, We may forward Your data to Our third party providers
        (please find the full list in Section 6). <br />
        <br />
        <strong>3. Kinds of data We collect </strong>
        <br />
        <br /> Depending on how You decide to Use the Service or whether Your
        subscribe or not, We may collect the following information from You:{' '}
        <br />
        <br />
        <ul>
          <li>
            &nbsp; Your personal data: name and last name, date of birth,
            address, email, phone (so that We could provide You with access to
            the Service; provide You with good customer service and User
            experience; to process payments; to provide You with insights,
            messages and updates - upon Your authorisation) <br />
            &nbsp;
          </li>
          <li>
            &nbsp; Your financial and bank data (including but not limited to
            bank or payment account information, transaction data, loan and
            investment data), so that We could provide the primary functions of
            the Service to You, including making payments as per Your
            authorisation and explicit consent. We may also collect and process
            personal data provided to Us by your Payment Account Provider, as
            long as it is needed for Us to fulfil Our obligations associated
            with the Agreement. <br />
            &nbsp;
          </li>
          <li>
            &nbsp; Your ID and other documents may be requested from You as
            prescribed by law (for Us to verify Your identity and conduct due
            diligence, for compliance and Our Service safety reasons) <br />
            &nbsp;
          </li>
          <li>
            &nbsp; Data about how You Use the Service (We may collect it, only
            to have the overall statistics; if collected, this data is purely
            depersonalised and cannot be linked to You)
            <br />
            &nbsp;
          </li>
          <li>
            &nbsp; Information about Your device and other technical
            information: Your IP address, time zone, geolocation,
            browser/platform used to access the Service (this is needed for Us
            to be able to adequately perform under the Agreement between You and
            Us (please refer to the Terms of Service), as well as for Our legal
            interests and obligations (e.g. detect fraud and keep Our platform
            secure). <br />
            &nbsp;
          </li>
        </ul>
        We will keep the aforementioned data for as long as You keep Using the
        Service and have a registered account with us. As soon as You choose to
        delete Your account and have Your data erased, We may keep it for
        another 30 days after which it will be fully erased. <br />
        <br />
        Please note that even if You do decide to have Your data erased and
        delete Your PAYMENTGUYS account, We still might hold some data about You
        that would be strictly needed for the purposes like fraud prevention,
        security, and improving of Our Service (no personal data will be kept in
        this case).&nbsp;
        <br />
        <br /> We will never keep any personal data about You for longer than
        needed or for any other purposes rather than listed in this Privacy
        Policy. <br />
        <br />
        <strong>4. Your Rights </strong>
        <br />
        <br />
        <ul>
          <li>
            &nbsp; The right to know what kind of data We collect about You and
            how do We process it. For further details, please read section 3 or
            contact Us at&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:info@payment-guys.com">
              info@payment-guys.com
            </a>
            . <br />
            &nbsp;
          </li>
          <li>
            &nbsp; The right to request certain data to be erased or the right
            to be fully forgotten. Please note that upon such a request We might
            not be further able to provide You with the Service (fully or
            partially). Please contact Us directly in case You have such a
            request. <br />
            &nbsp;
          </li>
          <li>
            &nbsp; Restrict processing of Your data and withdraw consent. At any
            time given, You can change the data processing settings right HERE
            or by contacting Us directly. <br />
            &nbsp;
          </li>
          <li>
            &nbsp; The right not to provide any personal data to Us. In this
            case, We might not be able to provide Our Service to You, fully or
            partially. <br />
            &nbsp;
          </li>
        </ul>
        You always have the right to file a complaint to the Information
        Commissioner's Office at&nbsp;
        <a target="_blank" rel="noreferrer" href="http://www.ico.org.uk/">
          www.ico.org.uk
        </a>
        &nbsp; in case You are unhappy about how We process Your data. <br />
        <br />
        <strong>
          5. How do We ensure Your data is safe with Us <br />
        </strong>
        <br /> As soon as We get access to Your data We will comply with the
        most up-to-date UK and EU security guidelines, to ensure Your data is
        safe. Administrative, physical, and technical security policies and
        procedures are put in place, for this purpose. <br />
        <br /> Your data is stored on Microsoft Azure servers that are
        continuously backed up. All of the sensitive personal data will always
        be encrypted upon its transmission to the 3rd parties. We will only
        transfer Your data to outside of the EEA when strictly prescribed by the
        applicable laws and regulations, or in order for Us to fulfill Our
        contractual obligations with You. In such a case, We will take
        additional measures of data protection. <br />
        <br /> Upon transferring to the 3rd Parties listed in section 6, We will
        Use data encryption. We also have strict data processing agreements and
        procedures with the 3rd Parties, under which We act as Data Controllers
        and We closely control how Your data is processed by Our partners.&nbsp;
        <br />
        <br /> However, please remember that You are responsible for the safety
        of the login and password information to Your account. We do not hold
        responsibility for the security of Your credentials. <br />
        <br />
        For more information on data security, feel free to contact&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="mailto:info@payment-guys.com
">
          info@payment-guys.com
        </a>
        . <br />
        <br />
        <strong>6. How do We exchange Your data</strong>
        <br />
        <br /> To effectively run Our Service, We must partner with third
        parties and, therefore, We may exchange Your data with: <br />
        <br />
        <ul>
          <li>
            &nbsp; Payment Account Providers (banks, card issuers, etc. -
            "Partners") <br />
            &nbsp;
          </li>
          <li>
            &nbsp; Third parties and service You choose to Use in connection
            with the Service <br />
            &nbsp;
          </li>
          <li>
            &nbsp; Our Trusted Data Aggregation Partners (Licensed Open Banking
            Platforms) <br />
            &nbsp;
          </li>
          <li>
            &nbsp; Potentially, other third parties, if we have to do so to
            fulfil our legal obligations&nbsp;
            <br />
            &nbsp;
          </li>
        </ul>
        <strong>
          7. Cookie policy <br />
        </strong>
        <br /> As soon as You open the website we automatically start receiving
        small text files containing some basic information about You (e.g. Your
        IP or browser information) - these are cookies. <br />
        <br /> We will only use cookies to the extent You authorise us to and We
        will notify You with a banner if and what kind of cookies We may
        collect, asking You to specify Your preferences in this regard. <br />
        <br /> We may use cookies to remember Your choices and preferences, to
        improve the overall experience and help Us to make Your use of the
        Service more personalised. <br />
        <br /> Some of the cookies are strictly necessary for the Service
        operation and we must collect them. For any of the rest, You can always
        choose to not let us collect these or withdraw Your consent, at any time
        given. <br />
        <br /> You can always turn off cookies directly in Your browser
        settings. Unfortunately, We cannot guarantee full availability and
        proper functioning of Our website in case You choose to refuse cookies.{' '}
        <br />
        <br /> We will not share Your cookies with third parties, without Your
        authorisation. <br />
        <br />
        <strong>
          8. Changes to the Privacy Policy <br />
        </strong>
        <br /> We may change the Privacy Policy, from time to time, in
        accordance with the changes in the applicable laws and regulations or
        changes in Our business procedures. Thus, We suggest that You check this
        page for the newest updates, from time to time. In case of any major
        updates, We will always make sure to notify You via the email address
        You have indicated upon registration.&nbsp;
        <br />
      </div>
    </div>
  );
}

export default Privacy;
