import Header from '../components/Header/Header';
import Success from '../components/Success/Success';
import Global from '../components/Global/Global';
import Slider from '../components/Slider/Slider';
import Potential from '../components/Potential/Potential';
import Breeze from '../components/Breeze/Breeze';
import Footer from '../components/Footer/Footer';
import Payment from '../components/Payment/Payment';

 function Home () {
  return (
    <>
    <Header />
      <Success />
    <div className='content'>
      <Global />
      <Slider />
      <Payment />
     </div>
     <Potential />
      <div className='content'>
      <Breeze />
      <Footer />
      </div>

      </>
  )
 }

 export default Home;