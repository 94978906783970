import React from "react";
import "./Payment.scss";
import Analytics from "../../images/analytics.svg";
import Zero from "../../images/zero.svg";
import Ok from "../../images/ok.svg";
import OneDay from "../../images/one-day.svg";
import Time from "../../images/time.svg";
import { motion } from 'framer-motion';
import { bottomAnimation, spawnAnimation} from '../../animation/animation';

function Payment() {
  return (
    <motion.section
    initial='hidden'
    whileInView='visible'
    viewport={{amount: 0.2, once: true}}
     className="payment">
      <span className="payment__ellipse"></span>

      <motion.h2 custom={1} variants={bottomAnimation} className="payment__title">Why choose PaymentGuys?</motion.h2>

      <motion.h3 custom={2} variants={bottomAnimation} className="payment__subtitle">
        PaymentGuys revolutionizes payment processes for Software as a Service
        (SaaS) platforms. We support instant, direct-to-account transactions
        that simplify your business operations. Focus on providing the best
        software solutions while we take care of your payment needs..
      </motion.h3>

      <motion.div
          initial='hidden'
          whileInView='visible'
          viewport={{amount: 0.2, once: true}}
          className="payment__box">
        <motion.img custom={3} variants={spawnAnimation}
          src={Zero}
          className="payment__image"
          alt="VISA payment system icon"
        />

        <motion.img custom={6} variants={spawnAnimation}
          src={Ok}
          className="payment__image"
          alt="VISA payment system icon"
        />
        <motion.div custom={5} variants={spawnAnimation}
         className="payment__time-box">
        <img
          src={Time}
          className="payment__image"
          alt="VISA payment system icon"
        />
        </motion.div>

        <motion.img custom={4} variants={spawnAnimation}
          src={Analytics}
          className="payment__image"
          alt="VISA payment system icon"
        />

        <motion.img custom={7} variants={spawnAnimation}
          src={OneDay}
          className="payment__image"
          alt="VISA payment system icon"
        />
      </motion.div>
    </motion.section>
  );
}

export default Payment;
