function Terms() {
  return (
    <div className="info">
      <h1 class="info__title">Terms and Conditions</h1>
      <div>
        We are PAYMENTGUYS FINANCE LTD Limited aka "PAYMENTGUYS" ("PAYMENTGUYS",
        "We", "Us", "Our"), (Incorporation Number: BC1417083) (Business Number:
        751449018BC0001) with the registered address: 408 - 55 WATER STREET
        OFFICE# 8936 VANCOUVER BC V6B 1A1 CANADA is an authorized Payment
        Institution and regulated by MSB registration number: M23066305. We
        operate the Website{' '}
        <a target="_blank" rel="noreferrer" href="https://payment-guys.com">
          https://payment-guys.com
        </a>{' '}
        . <br />
        <br />
        PAYMENTGUYS FINANCE LTD Limited is registered with the Financial Conduct
        Authority under the Payment Services Regulations 2017 for the provision
        of payment services. Reference no. 832969. <br />
        <br /> Please feel free to reach out to Us at{' '}
        <a target="_blank" rel="noreferrer" href="mailto:info@payment-guys.com">
          info@payment-guys.com
        </a>{' '}
        if You have questions or inquiries. We can also be contacted in-app via
        our help widget. <br />
        <br />
        <strong>1. The Agreement </strong>
        <br />
        <br />
        These Terms of Service shall serve as a legally binding contract between
        its Users ("You", "User", "Customer") and PAYMENTGUYS, therefore, We
        suggest reading the document carefully. The Terms of Service, along with
        the Privacy Policy shall constitute the entire Agreement (further -
        "Agreement") between You and PAYMENTGUYS. All of the services described
        below are the services provided by PAYMENTGUYS and shall be collectively
        referred to as the "Service". By using the Service, You acknowledge that
        You accept and agree to be bound by the terms in the Agreement. <br />
        <br />
        PAYMENTGUYS grants You a limited, revocable, non-exclusive,
        non-transferable license to access and use the Service for Your own
        individual use, subject to the other terms of this Terms of Service. All
        rights, title, and interest in and to the Service not expressly granted
        herein are reserved by PAYMENTGUYS. <br />
        <br /> We may, from time to time, make changes and adjustments to the
        Terms of Service and may not always be able to provide an update.
        Nevertheless, We will always make our best efforts to notify You of any
        material changes to Your email. <br />
        <br /> Should You not agree to any provisions of the Agreement, We
        suggest that You stop using the Service. <br />
        <br />
        <strong>2. Service We provide</strong>
        <br /> <br />
        In the scope of this Agreement between You and Us, PAYMENTGUYS serves as
        a Payment Initiation Service Provider ("PISP") and We will provide You
        with Payment Initiation Services ("PIS"). What it essentially means, We
        will facilitate the payment process for You by transferring funds
        directly from Your linked payment account(s) to the chosen beneficiary,
        upon Your explicit consent. <br />
        <br />
        After You select a Payment account provider or a Bank account provider
        (both further individually referred to as a "Partner") You want to link
        and/or make a payment from, You will be redirected to its official page
        (either in the app or in a browser) where You will need to undergo a
        verification process. <br />
        <br /> We will also serve as an Account Information Services Provider
        (AISP). The Account Information Service (AIS) enables You to access and
        view information about all Your online banking accounts in one place,
        and analyzes Your financial information to help You spend, save, borrow,
        and track Your funds flows more efficiently. <br />
        <br /> PAYMENTGUYS does not provide any banking and or payment account
        services. Thus, Your chosen payment account provider is solely
        responsible for the execution of payments and any flaws in the
        functionality of its services. Nevertheless, if We consider that We are
        lacking permissions or for any reason providing our services to You
        would be unlawful We may choose not to provide the Service to You.{' '}
        <br />
        <br /> Within the scope of this Agreement and within the boundaries You
        allow Us to, We will share Your account information with other services
        and third parties of Your choice. Once such a partner gets access to
        Your information it automatically becomes responsible for it and the
        information becomes a subject to Your agreement with the corresponding
        partner and its Privacy Policy. <br />
        <br /> We will only have access to any of the accounts You link if You
        provide Your consent for it and only within the scope of the service We
        provide. PAYMENTGUYS will never have access to, collect, or hold Your
        bank account or payment account credentials. <br />
        <br /> The Service shall be provided "as is" and on the "as available"
        basis. We do not make any warranty that: <br />
        <br />
        <ul>
          <li>
            {' '}
            The Service will meet Your requirements; <br />{' '}
          </li>
          <li>
            {' '}
            The Service will be uninterrupted, timely, secure, or error-free;{' '}
            <br />{' '}
          </li>
          <li>
            {' '}
            The results that may be obtained from the use of the Service will be
            accurate or reliable; <br />{' '}
          </li>
          <li>
            {' '}
            The quality of any products, services, information, or other
            material purchased or obtained by You through the Service will meet
            Your expectations; or <br />{' '}
          </li>
          <li> Any errors in the technology will be corrected. </li>
        </ul>
        <br />
        <strong>3. Access to the Service </strong>
        <br />
        <br /> By using our provided services You represent and consent that:{' '}
        <br />
        <br />
        <ul>
          <li>
            {' '}
            You are at least 18 years old and have the legal capacity and
            authority to enter into a contract <br />{' '}
          </li>
          <li>
            {' '}
            You will only use the Service as an individual and purely for
            private purposes <br />{' '}
          </li>
        </ul>
        <ul>
          <li>
            {' '}
            You will not use the Service for any fraudulent purpose <br />{' '}
          </li>
          <li>
            {' '}
            You will not use the Services for any other unlawful purpose <br />{' '}
          </li>
          <li>
            {' '}
            You agree to Our Privacy Policy <br />{' '}
          </li>
          <li>
            {' '}
            You are responsible for keeping You account credentials safe and
            secure; You may not disclose Your credentials to any third party. To
            the extent allowed by the applicable law, You shall be liable for
            any expenses and reasonable attorney's fees for Your failure to
            safeguard the account credentials and/or promptly notify PAYMENTGUYS
            about unauthorized use of Your account or compromising of Your
            account information or password <br />{' '}
          </li>
          <li>
            {' '}
            You will not create an account using a false or stolen identity. You
            represent and warrant that all the information provided by You
            within the scope of this Agreement is true and accurate <br />{' '}
          </li>
          <li>
            {' '}
            You may not resell or otherwise transfer the rights to use the
            Service <br />{' '}
          </li>
          <li>
            {' '}
            You may not decompile or disassemble the Service or use it, not in
            accordance with the purposes as per Agreement <br />{' '}
          </li>
          <li>
            {' '}
            You may not access the Service with the purpose of facilitating
            competitor products or services <br />{' '}
          </li>
          <li>
            {' '}
            Any forbidden use shall immediately terminate Your license to the
            Service.{' '}
          </li>
        </ul>
        <strong>
          <br />
          4. Our access to Your data{' '}
        </strong>
        <br />
        <br /> To have full access to the Service, You must choose the banks or
        other Payment Account providers that You would like to connect or
        authorize Us to make payments from. Once You choose a Partner, We will
        redirect You to its webpage where You will be taken through a secure
        process to access information from Your Payment Accounts (here and
        elsewhere in the Agreement "Payment Account" shall refer to an account
        created with a Partner). You will be asked to provide Your explicit
        consent to enable Us to access Your financial data. <br />
        <br /> You consent that You grant PAYMENTGUYS a non-exclusive,
        royalty-free licence to use the information in Your payment Accounts for
        all purposes connected with the Service or referred to in these
        Agreement, with the right to use, modify, display, distribute and create
        new material using or incorporating such information to provide the
        Service to You. We guarantee that We shall not use or access Your data
        for any other purposes, unless otherwise prescribed by law. To keep Your
        data safe and Your consent up-to-date, We will periodically ask You to
        renew Your consent. <br />
        <br /> You agree that when We retrieve Your information relating to Your
        payment Accounts or Your information required as part of the provision
        of the Service, We are doing so as Your representative and on Your
        behalf and not on behalf of or in the name of any third party. <br />
        <br /> By using the Service, You represent that You are the legal owner
        of the data in Your Payment Accounts and that You have the authority to
        appoint, and do expressly appoint Us and/or Our third-party providers as
        Your agent and grant a limited power of attorney to access and retrieve
        such data on Your behalf. <br />
        <br />
        For our compliance purposes and in order to provide the Service to You,
        You hereby authorise Us to, directly or through a third-party, obtain,
        verify, and keep a record of the information and documentation that
        helps Us verify Your identity and Payment Account information. When You
        register for the Service and from time to time thereafter, We may
        require You to provide and/or confirm information and documents that
        will allow Us to identify You. <br />
        <br />
        <strong>
          5. Account termination and suspension <br />
        </strong>
        <br /> You are free to terminate Your account or withdraw any consent
        given to Us at any time by contacting Us at{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="mailto:info@payment-guys.com
">
          info@payment-guys.com
        </a>{' '}
        or directly from Your account. <br />
        <br /> We may evaluate and determine, in our discretion, whether there
        has been a breach of this Agreement through Your use of the Service.
        When such a breach has occurred, We may take such action as We deem
        appropriate, including all or any of the following actions: <br />
        <br />
        <ul>
          <li>
            {' '}
            immediate temporary or permanent withdrawal of Your right to use the
            Service; <br />{' '}
          </li>
          <li>
            {' '}
            issue of a warning to You; <br />{' '}
          </li>
          <li>
            {' '}
            legal proceedings against You for reimbursement of all costs
            (including, but not limited to, reasonable administrative and legal
            costs) resulting from the breach; <br />{' '}
          </li>
          <li>
            {' '}
            further legal action against You; and/or <br />{' '}
          </li>
          <li>
            {' '}
            disclosure of such information to law enforcement authorities as We
            reasonably feel is necessary. <br />
            <br />{' '}
          </li>
        </ul>
        The responses described above are not limited and We may take any other
        action We reasonably deem appropriate and that doesn't go against the
        applicable law. <br />
        <br /> We may choose to reject Your registration or cancel an existing
        registration for any other reason at our sole discretion. <br />
        <br />
        <strong>6. Indemnification and liability </strong>
        <br />
        <br /> You agree to protect and fully compensate PAYMENTGUYS and our
        affiliates from any and all third party claims, liability, damages,
        expenses and costs (including, but not limited to, reasonable
        solicitors' fees) caused by or arising from Your use of the Service,
        Your violation of this Agreement or Your infringement, or infringement
        by any other user of Your account, of any intellectual property or other
        right of anyone. <br />
        <br /> We will have no liability to You for any failure or delay in
        performing any of our obligations under this Agreement to the extent
        that such failure or delay is caused or contributed to by You or by an
        event or circumstance beyond our reasonable control. <br />
        <br /> IN NO EVENT SHALL PAYMENTGUYS, ITS OFFICER, DIRECTORS, AGENTS,
        AFFILIATES, EMPLOYEES, ADVERTISES, OR PROVIDERS BE LIABLE FOR ANY
        INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES INCLUDING, BUT
        NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA, WHETHER
        IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO
        NEGLIGENCE), EQUITY OR OTHERWISE, OR FOR DAMAGES IN THE AGGREGATE
        EXCEEDING THE AMOUNT OF THE FEES PAID AND PAYABLE BY YOU. <br />
        <br />
        <strong>
          7. Complaints <br />
        </strong>
        <br /> We kindly ask You to address Your concerns and complaints
        directly to Us via{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="mailto:info@payment-guys.com
">
          info@payment-guys.com
        </a>{' '}
        and We will always assist You in our best effort. In case You end up
        being unhappy with our response or with the overall service provided,
        You have the right to take Your complaint to the{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.financial-ombudsman.org.uk/">
          Financial Ombudsman Service
        </a>
        . <br />
        <br /> If Your complaint is about how We store or process Your personal
        information, You can also contact the{' '}
        <a target="_blank" rel="noreferrer" href="https://ico.org.uk/concerns/">
          Information Commissioner's Office
        </a>
        . Again, We'd appreciate the chance to deal with Your concerns first.{' '}
        <br />
        <br />
        <strong>8. General provisions </strong>
        <br />
        <br />
        This Agreement is governed by the laws of the United Kingdom. If a court
        decides that We cannot rely on a part of this Agreement, the rest of the
        Agreement will continue to apply, as each of the provisions of this
        Agreement operate separately. <br />
        <br /> If any provision of this Agreement is found to be invalid for any
        reason, the invalidity of that provision will not affect the remaining
        provisions of the Agreement, which will remain in full force and effect.{' '}
        <br />
      </div>
    </div>
  );
}

export default Terms;
