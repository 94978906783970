import React from "react";
import { MButton } from "../Button/Button";
import Card from "../../images/card.svg";
import CardTwo from "../../images/card2.svg";
import CardThree from "../../images/card3.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Slider.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { motion } from 'framer-motion';
import { bottomAnimation, spawnAnimation } from '../../animation/animation';

function Slider() {
  const params = {
    spaceBetween: 20,
    pagination: {
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 2,
      },
      1366: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <motion.section 
    initial='hidden'
    whileInView='visible'
    viewport={{amount: 0.2, once: true}}
     className="slider">

      <span className="slider__ellipse"></span>
      <div className="slider__block-info">
        <motion.h2 custom={1} variants={bottomAnimation} className="slider__title">
          Streamline Your Business Payments with PaymentGuys
        </motion.h2>
        <motion.h3 custom={2} variants={bottomAnimation} className="slider__subtitle">
          Explore Use Cases Across Various Industries
        </motion.h3>
        <motion.p custom={3} variants={bottomAnimation} className="slider__paragraph">
          With PaymentGuys, receiving payments is a breeze. There are no
          middlemen, no massive fees, and no chargebacks – just easy,
          direct-to-account transactions. Say goodbye to complex payment
          processing and hello to simplicity with PaymentGuys.
        </motion.p>
      </div>

      <div className="slider__box">
        <Swiper {...params}>
          <SwiperSlide>
            <motion.img custom={4} variants={spawnAnimation} src={Card} alt="card" />
          </SwiperSlide>

          <SwiperSlide>
            <motion.img custom={5} variants={spawnAnimation} src={CardTwo} alt="card" />
          </SwiperSlide>

          <SwiperSlide>
            <motion.img custom={6} variants={spawnAnimation} src={CardThree} alt="card" />
          </SwiperSlide>

          <SwiperSlide>
            <motion.img custom={7} variants={spawnAnimation} src={Card} alt="card" />
          </SwiperSlide>

          <SwiperSlide>
            <motion.img custom={8} variants={spawnAnimation} src={CardTwo} alt="card" />
          </SwiperSlide>
        </Swiper>
      </div>

      <MButton custom={6} variants={bottomAnimation} text={"Unlock the Payment Breeze"} />
    </motion.section>
  );
}

export default Slider;
