import Portrait from "../../images/portrait.jpg";
import PortraitXXL from "../../images/portrait-xxl.jpg";
import MButton from "../Button/Button";
import "./Success.scss";
import { motion } from 'framer-motion';
import { bottomAnimation, spawnAnimation} from '../../animation/animation';

function Success() {
  return (
    <motion.section initial='hidden'
    whileInView='visible'
    viewport={{amount: 0.2, once: true}}
     className="success">

      <motion.picture variants={spawnAnimation}>
        <source srcSet={Portrait} media="(max-resolution: 192dpi)" />
        <source srcSet={PortraitXXL} media="(max-resolution: 193dpi)" />
        <img src={Portrait} className="success__image" alt="Portrait" />
      </motion.picture>

      <motion.div custom={2} variants={bottomAnimation} className="success__block-info">
        <h2 className="success__title">
          Skyrocket Your Revenue per User by up to 7x{" "}
        </h2>

        <motion.p custom={2} variants={bottomAnimation} className="success__paragraph">
          Existing payment options have their limitations: card transactions
          incur high fees, bank transfers often provide a subpar customer
          experience, and bank debit falls short in optimizing one-time
          payments. Introducing Instant Bank Pay, a solution that seamlessly
          integrates with bank debit through open banking. With instant
          bank-to-bank payments, transactions are confirmed in real-time,
          granting improved visibility for both you and your customers. Say
          goodbye to the hassle of chasing one-time payments and welcome a
          frictionless customer experience.
        </motion.p>

        <MButton custom={3} variants={bottomAnimation} text={"Unlock Payment Success"} />
      </motion.div>
    </motion.section>
  );
}

export default Success;
