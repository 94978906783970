import './App.scss';
import Home from '../../pages/Home';
import Terms from '../../pages/Terms';
import Privacy from '../../pages/Privacy';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
      </Routes>
    </div>
  );
}

export default App;